<template>
	<el-header>
		<p>奇天幻影</p>
		<div>
			<span>{{userName}}</span>
			<el-dropdown >
				<i class="el-icon-setting" style="margin-right: 15px;color: white;"></i>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
		
		
	</el-header>
</template>

<script>
	export default{
		data(){
			return{
				userName:''
			}
		},
		mounted(){
			let shuju = localStorage.getItem('userInfo')
			this.userName=JSON.parse(shuju).name
			this.$store.state.userInfo = JSON.parse(shuju)
		},
		methods:{
			logout(){
				// 清除缓存
				window.localStorage.clear();
				// this.$router.push("/login");
				// 刷新页面
				window.location.reload()
			}
		},
	}
</script>

<style>
	.el-header {
		background-color: #1a1b20;
		color: #333;
		line-height: 60px;
		width: 100%;
		color: white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 20px;
	}
	.el-header p{
		font-size: 30px;
		font-weight: bolder;
		font-style:oblique;
		font-family: "blackadder itc";
	}
</style>
