<template>
	<el-container>
		<el-header>
			<top></top>
		</el-header>
		
		<el-container>
			<el-aside>
				<left></left>
			</el-aside>
			<el-main>
				 <router-view />
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
	import left from "../../components/left.vue";
	import top from "../../components/top.vue"
	export default {
		data() {
			return{
			}
		},
		created(){
			//将登录账号数据存入vuex
			let shuju = localStorage.getItem('userInfo')
			this.$store.state.userInfo = JSON.parse(shuju)
			//将用的地址存入vuex
			let space = localStorage.getItem('space')
			this.$store.state.space = JSON.parse(space)
		},
		methods:{},
		components:{
			left,top
		}
	}
</script>

<style>
	html,body,#app,section,.el-container,.el-main,.el-aside{
		height: 100%;
	}
	aside{
		width: 200px !important;
	}
</style>
