<template>
	<el-aside>
		<el-menu 
			router 
			background-color='#1a1b20'
			text-color='#e5e7e4'
			active-text-color="#ffd04b"
			:default-active='lujing'
			>
			<!-- :default-active=lujing -->
			<el-menu-item index="/user" :disabled="type == 2 ? false : true">
				<i class="el-icon-user-solid"></i>
				<span slot="title">用户</span>
			</el-menu-item>
			<el-submenu index="2" :disabled="type == 2 ? false : true" >
				<template slot="title">
						<i class="el-icon-s-shop"></i>
						<span slot="title">门店</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="/shebei">
						<i class="el-icon-cpu"></i>
						<span slot="title">设备管理</span>
					</el-menu-item>
					<el-menu-item index="/game">
						<i class="el-icon-coordinate"></i>
						<span slot="title">游戏管理</span>
					</el-menu-item>
					<el-menu-item index="/shop">
						<i class="el-icon-takeaway-box"></i>
						<span slot="title">门店管理</span>
					</el-menu-item>
					<el-menu-item index="/lunbo">
						<i class="el-icon-picture"></i>
						<span slot="title">轮播图</span>
					</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-menu-item index="/dingdan" :disabled="type == 2 ? false : true">
				<i class="el-icon-s-order"></i>
				<span slot="title">订单</span>
			</el-menu-item>
			<el-menu-item index="/youxi" :disabled="type == 2 ? false : true">
				<i class="el-icon-place"></i>
				<span slot="title">游戏</span>
			</el-menu-item>
			<el-menu-item index="/partner" :disabled="type == 2 ? false : true">
				<i class="el-icon-bank-card"></i>
				<span slot="title">合作伙伴</span>
			</el-menu-item>
			<el-menu-item index="/caiwu" :disabled="type == 2 ? false : true">
				<i class="el-icon-bank-card"></i>
				<span slot="title">财务</span>
			</el-menu-item>
			<el-menu-item index="/refund" :disabled="type == 2 ? false : true">
				<i class="el-icon-bank-card"></i>
				<span slot="title">退款</span>
			</el-menu-item>
		</el-menu>
	</el-aside>
</template>

<script>
	export default{
		data(){
			return{
				type:'',
				lujing:this.$route.path
			}
		},
		mounted(){
			this.type = 2
		}
		
	}
</script>

<style lang="less">
	.el-aside{
		background: #1a1b20;
		height: 100%;
		&>span{
			text-align: start;
		}
	}
	.el-menu{
		border: none;
	}
</style>
